(function ($) {
  popupOfferV1 = {
    formatter: $('.popup-offer-v1')
  };

  Drupal.behaviors.popupOfferV1 = {
    get: function (key) {
      return popupOfferV1[key];
    },

    set: function (key, val) {
      popupOfferV1[key] = val;
    },

    showPopupWindow: function () {
      var status = 0;
      /* 
     * language interstitial states
     * loaded  - the default state of the language interstitial
     * viewing - the user is currently viewing the language interstitial
     * closed  - the user saw the language interstitial and just closed it
    */
      var interstitial = $('#interstitial-content-deck').length ? $('#interstitial-content-deck').attr('interstitial_loaded') : '';

      // show the popup when on US
      if ($(document.body).hasClass('domain-us')) {
        status = 1;
      }

      // show the popup because the interstitial was shown and closed or it was loaded before the execution of this code
      else if ((generic.cookie('CA_interstitial') && (interstitial == 'closed' || interstitial == 'loaded')) && $(document.body).hasClass('domain-ca')) {
        status = 1;
      }

      // the language interstitial was already shown to the user
      else if (generic.cookie('CA_interstitial') && $('#interstitial-content-deck').length == 0) {
        status = 1;
      }

      return status;
    },

    attach: function (context, settings) {
      var self = this;
      var formatter = self.get('formatter');

      formatter.each(function () {
        var thisFormatter = $(this);
        var offerCode = thisFormatter.children().attr('offer-code') || 1;
        var current_path = location.pathname;

        function check_disabled_path() {
          var disabled_path_selector = thisFormatter.find('.gnav-disabled-pages');

          if (disabled_path_selector.length > 0) {
            var disabled_paths = disabled_path_selector.text().split(',');

            for (var i = 0; i < disabled_paths.length; i++) {
              var disabled_path = disabled_paths[i];

              disabled_path = disabled_path.trim();
              disabled_path = disabled_path == '<front>' ? '/' : '/' + disabled_path;
              if (current_path == disabled_path) {
                return true;
              }
            }
          }

          return false;
        }

        // Hide thank you and signed up blocks
        thisFormatter.find('.outer-wrap.thankyou').hide();
        thisFormatter.find('.outer-wrap.signedup').hide();

        // Hide popup offer on Popup Offer Terms & Conditions page
        if ($('body').hasClass('section-popup-offer-terms')) {
          thisFormatter.find('.outer-wrap.signup').hide();
        }

        // create close button
        thisFormatter.find('.outer-wrap').once().prepend('<div title="Hide" class="popup-offer-close" />');
        thisFormatter.find('.popup-offer-close').once().on('click', function() {
          thisFormatter.hide();
        });

        // control whether or not to show popup (set in the CMS)
        // the node to control this is 17892
        var popupOfferStatus = thisFormatter.find('.showPopupOffer').attr('data-attr');
        // Get the node ID
        var nodeId = thisFormatter.closest('[data-node-nid]').data('node-nid');

        if (popupOfferStatus === 'on') {
        // check session cookie
          var cookie = '';
          var count = 0;
          var cookieArray = [];
          var wholeCookie = $.cookie('hide_popup_offer');
          var dsgv_clear_popup_cookie = Drupal.settings.globals_variables.clear_popup_cookie;

          if (wholeCookie !== null && !dsgv_clear_popup_cookie) {
            try {
              var cookieArray = JSON.parse(wholeCookie);
            } catch (err) {
              $.cookie('hide_popup_offer', null);
            }
          } else {
            cookie = '';
          }

          if (cookieArray !== null && Array.isArray(cookieArray) === true) {
            cookie = cookieArray[0];
            count = cookieArray[1];
          }

          var sessionCookie = sessionStorage.getItem('hide_popup_offer_session');
          // cookie = cookie === undefined ? "" : cookie;
          // Keep track of popup using node ID if there is no offerCode
          var checkVal = offerCode != 1 ? offerCode : nodeId;
          var re = new RegExp('\\b' + checkVal + '\\b', 'g');

          // If cookie does not exist or no match for checkVal (already shown) and not a disabled path
          if (((cookie === undefined || cookie === '' || !!cookie.match(re) && !check_disabled_path()) && count < 2) || (sessionCookie === null || sessionCookie !== 'hidden')) {
            var newCookieVal = checkVal + ' ' + cookie;
            var newCount = count + 1;
            var newCookieArray = [newCookieVal, newCount];

            $.cookie('hide_popup_offer', JSON.stringify(newCookieArray), {
              expires: 1,
              path: '/'
            });
            if (dsgv_clear_popup_cookie) {
              sessionStorage.setItem('hide_popup_offer_session', 'hidden');
              $.cookie('hide_popup_offer_session', 'hidden', { path: '/' });
            }

            // offer code is an optional field
            if (offerCode.length > 0) {
            // check offer code timing status
              generic.jsonrpc.fetch({
                method: 'offer',
                params: [offerCode],
                onSuccess: function (r) {
                  var offer = r.getValue();
                  var isActive = offer && (offer.timing === 'active');

                  if (isActive && self.showPopupWindow()) {
                  // if active, show
                    thisFormatter.delay(1600).fadeIn(); // empty fadeIn() animation speed defaults to 400, was previosuly set to 1200.
                  }
                },
                onFailure: function () {
                // started pg server?
                }
              });
            } else {
            // if no offerCode and not shown before (not found in cookie values)
              thisFormatter.delay(1600).fadeIn(1200);
            } // end offer code check
          } // end cookie check          
        }

        // Submit form
        var form = thisFormatter.find('.signup-forms .popover-newsletter-signup #popover-newsletter-signup');

        form.find('.form-submit.button').on('click', function(e) {
          e.preventDefault();

          // we want to get all the fields in code because 'serialize' encodes potentially bad emails and decode doesn't decode '+' as spaces        
          var fields = [
            'PC_EMAIL_ADDRESS',
            'PC_EMAIL_PROMOTIONS',
            'LAST_SOURCE',
            'LANGUAGE_ID',
            'COUNTRY_ID',
            'LAST_SOURCE',
            'ORIGINAL_SOURCE'
          ];
          var paramObj = {};

          paramObj['_SUBMIT'] = 'email_signup';

          // loop through all the fields and get the values
          $.each(fields, function (index, value) {
            let formFieldValue = form.find('input[name=' + value + ']').val();

            if (form.find('input[name=' + value + ']').is(':checkbox')) {
              if (!form.find('input[name=' + value + ']').prop('checked')) {
                formFieldValue = 0;
              } else {
                formFieldValue = 1;
              }
            }
            paramObj[value] = formFieldValue;
          });

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [paramObj],
            onSuccess: function (jsonRpcResponse) {
              var response = jsonRpcResponse.getData();
              // was the user previously opted into email promotions before this submission?
              // this is a 0/1 value
              var previously_opted_in_email_promotion = response.userinfo.previously_opted_in_email_promotion;
              // are we still running special offer codes which are emailed out? 
              // this is a 0/1 value
              var special_offer_code_status = response.userinfo.special_offer_code_status;

              // user was not previously opted and we are running special offer emails
              if (!previously_opted_in_email_promotion && special_offer_code_status) {
                thisFormatter.find('.outer-wrap.signedup').hide();
              } else { // show normal success message since user was already signed up
                $('body').addClass('popup-offer');
                if ($('body.popup-offer').hasClass('pg')) {
                  $('body.pg').addClass('popup-signedup');
                // thisFormatter.find('.outer-wrap.signedup').removeClass('hidden');
       	      }
                thisFormatter.find('.outer-wrap.signedup').show();
              }

              thisFormatter.find('.outer-wrap.signup').hide();
              thisFormatter.find('.outer-wrap.thankyou').show();
            },
            onFailure: function (jsonRpcResponse) {
              var messages = jsonRpcResponse.getMessages();

              $('.error').empty().html(messages[0].text).removeClass('hidden');
            }
          });
        });

        // Manual popup offer
        $(window).on('show_popup_offer', function () {
        // show popup code
          thisFormatter.delay(1600).fadeIn(1200);
        });
      });
    }
  };
})(jQuery);
